import { useState } from "react"
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';

export const FreeComment = (props) => {
    const {
        name = '',
        label = '',
        onChange = () => { },
        required = false,
    } = props

    const [value, setValue] = useState('');

    const _onChange = (e) => {
        e.stopPropagation()
        setValue(e.currentTarget.value)
        onChange(e.currentTarget.value)
    }

    return (
        <div className="my-2">
            <FloatingLabel
                controlId="floatingTextarea2"
                label={label}>
                <Form.Control
                    as="textarea"
                    name={name}
                    onChange={_onChange}
                    placeholder={label}
                    value={value}
                    style={{ minHeight: '100px' }}
                    required={required}
                />
            </FloatingLabel>
        </div>
    )
}