import { useState } from 'react'
import ListGroup from 'react-bootstrap/ListGroup';
import Button from 'react-bootstrap/Button';
import IsMobile from '../utils/IsMobile';


export const TeenScaleValue = (props) => {
    const {
        name = '',
        onChange = () => {},
        label = '',
        minLabel = '',
        maxLabel = '',
        defaultValue = null
    } = props

    const isMobile = IsMobile();
    const [value, setValue] = useState(defaultValue && !isNaN(defaultValue) ? parseInt(defaultValue) : 5);

    const _onChange = (newValue) => {
        setValue(newValue)
        onChange(newValue)
    }


      const getColorRGB = (i) => {
        if (i >= 0 && i <= 2) {
          return 'rgb(215, 25, 33)'; // Rojo
        } else if (i === 3) {
          return 'rgb(255, 100, 0)'; // Naranja
        } else if (i === 4) {
            return 'rgb(255, 150, 0)'; // Naranja
        }
        else if (i === 5) {
            return 'rgb(255, 193, 0)'; // Naranja
        }
        else if (i === 6) {
            return 'rgb(213, 225, 0)'; // Naranja
        }
        else if (i === 7) {
            return 'rgb(160, 238, 0)'; // Naranja
        }
        else if (i === 8) {
            return 'rgb(89, 244, 0)'; // Naranja
        }
        else if (i === 9) {
            return 'rgb(0, 212, 65)'; // Naranja
        }
          else if (i === 10) {
          return 'rgb(0, 128, 0)'; // Verde oscuro
        }
      
        return 'rgb(211, 211, 211)'; // Por defecto, en caso de algún valor inesperado
      };
      
    
    const renderBttns = () => {
        const bttns = Array.from({ length: 11 }, (_, i) => i)
        return bttns.map(i => (
            <div key={i} className={"w-100 " + (isMobile ? ' p-0' : ' p-1')}
                style={{
                    padding: isMobile ? '4px 4px' : {},
                    border: value === i ? '2px solid white' : 'none',
                    borderRadius: '10px'
                }}>
                <ListGroup.Item
                    className={"w-100 " + (isMobile ? ' p-0' : ' p-0')}
                    style={{ margin: '0', border: '1px solid rgba(215, 25, 33,255)', borderRadius: '10px'}} 
                    key={i}>    
                    <Button
                        onClick={() => _onChange(i)}
                        className={'w-100' + (value === i ? ' active' : '')}
                        style={{
                            padding: isMobile ? '4px 4px' : {},
                            backgroundColor: getColorRGB(i), 
                            border: '1px solid pink'
                        }}
                        >{i}
                    </Button>
                </ListGroup.Item>
            </div>
        ))
    }

    return (
        <div className='red-background' style={{ marginBottom: '0' }}>
            <div className='my-0'  style={{ color: 'white', backgroundColor: 'rgba(215, 25, 33,255)' }}>
                <label className='my-0'>{label}</label>
                <input type='hidden' name={name} value={value} />
                <div className='d-flex justify-content-between'>
                    <span>{minLabel}</span>
                    <span>{maxLabel}</span>
                </div>
                {isMobile ? (
                    <>
                        <ListGroup horizontal size='sm' className="mb-0" >
                            {renderBttns()}
                        </ListGroup>
                    </>
                ) : (

                    <ListGroup horizontal className="mb-0">
                        {renderBttns()}
                    </ListGroup>
                )}
            </div>    
        </div>
    )
}