import { useState } from 'react'
import ListGroup from 'react-bootstrap/ListGroup';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faThumbsUp, faThumbsDown } from '@fortawesome/free-solid-svg-icons'

export const LikeOrDislike = (props) => {
    const {
        name = '',
        onChange = () => { },
        label = ''
    } = props

    const [value, setValue] = useState(`undefined`);

    const _onChange = (newValue) => {
        setValue(newValue)
        onChange(newValue)
    }

    return (
        <tbody>
            <tr>
                <td className="border">  
                    <div className='my-0 d-flex align-items-center justify-content-between'>
                        <label className='my-0 mr-2' >{label}</label>
                        <input type='hidden' name={name} value={value} />
                        <ListGroup horizontal className="mb-0">
                            <ListGroup.Item className={"p-1"}>
                                <Button
                                    onClick={() => _onChange(true)}
                                    variant={value === true ? "success" : "light"}
                                    className={'w-100' + (value === true ? ' active' : '')}
                                >
                                    <FontAwesomeIcon icon={faThumbsUp} />
                                </Button>
                            </ListGroup.Item>
                            <ListGroup.Item className={"p-1"}>
                                <Button
                                    onClick={() => _onChange(false)}
                                    variant={value === false ? "danger" : "light" }
                                    className={'w-100' + (value === false ? ' active' : '')}
                                >
                                    <FontAwesomeIcon icon={faThumbsDown} />
                                </Button>
                            </ListGroup.Item>
                        </ListGroup>
                    </div>
                </td>
            </tr>
        </tbody>
    )

}
