export function convertValueFromStr(val) {
    if (val === false) return 0;
    if (val === true) return 1;
    if (!isNaN(val) && val.toString().indexOf('.') !== -1) return parseFloat(val)
    if (!isNaN(val)) return parseInt(val)
    return val
}

export const SerializeForm = (form) => {
    const data = new FormData(form)


    let objSerializedForm = [];
    for (let [name, value] of data) {
        objSerializedForm.push({key : name, value : value});
    }

    return objSerializedForm
}