import Button from 'react-bootstrap/esm/Button';
import './common.scss';
import Logo from './images/makro-logo.png'

import { FreeComment } from './components/FreeComment';
import { TeenScaleValue } from './components/TeenScaleValue'
import { SerializeForm } from './utils/SerializeForm';
import { useEffect, useState } from 'react';
import { LikeOrDislike } from './components/LikeOrDislike';
import { Table } from 'react-bootstrap';


function App() {
  const [probabilidadRecomendacion, setProbabilidadRecomendacion] = useState(9)
  const [tiendaId, setTiendaId] = useState(0)
  const [envioId, setEnvioId] = useState(0)
  const [envioFechaHora, setEnvioFechaHora] = useState('')
  const [tipoCliente, setTipoCliente] = useState('')
  const [clienteNro, setClienteNro] = useState('')
  const [visitaFecha, setVisitaFecha] = useState('')
  const [emailCliente,setEmailCliente] =useState('')
  

  const [ready, setReady] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [submitError, setsubmitError] = useState(false)


  // Se ejecuta la primera vez que se carga la pagina
  useEffect(() => {
    const url = new URL(window.location.href)

    if (url.searchParams.has('param-option-selected'))
    setProbabilidadRecomendacion(url.searchParams.get('param-option-selected'))
    setTiendaId(url.searchParams.get('store'))
    setEnvioId(url.searchParams.get('send'))
    setEnvioFechaHora(url.searchParams.get('send-date-time'))
    setVisitaFecha(url.searchParams.get('visit-date'))
    setTipoCliente(url.searchParams.get('tipo-cliente'))
    setClienteNro(url.searchParams.get('cliente-nro'))
    setEmailCliente(url.searchParams.get('utm_email'))

    setReady(true)
  }, [])

  const onSubmit = async (e) => {
    e.preventDefault();
    let form = e.target;
    const data = SerializeForm(form);
  
    // console.log(data)  
    try {
      setIsSubmitting(true); // Comienza el envío
      setsubmitError(false)
      
      const url = 'https://makro.neuralactions.com/api/send_data';
      const headers = {
        'Content-Type': 'application/json',
      };
  
      const response = await fetch(url, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({data : data}),
      });
  
      if (response.ok) {
        // const result = await response.json();
        await response.json();
        // console.log('Encuesta enviada exitosamente:', result);
        setSubmitSuccess(true); // Marca como éxito
      } else {
        // console.error('Error al enviar la encuesta');
        // Manejo de errores si la llamada no fue exitosa
        setsubmitError(true)
      }
    } catch (error) {
      // console.error('Error al enviar la encuesta', error);
      // Manejo de errores en caso de excepción
      setsubmitError(true)
    }
  };

  if(!ready) {
    return (null)
  }

  return (
    <div className="App">
      <form className='container-xl' onSubmit={onSubmit}>
        <div className="contenedor-logo"  >
          <img src={Logo} alt='Makro'/>
        </div>
        <div className='back-recommender-top' >
          <div>
            <h5 style={{ color: 'white', backgroundColor: 'rgba(215, 25, 33,255)' }}> De 0 a 10. ¿Cuan probable es que recomiendes Makro a un amigo, pariente o compañero de trabajo?</h5>
          </div>
        </div>
        <TeenScaleValue
          name='probabilidad_recomendacion'
          minLabel='Nada probable'
          maxLabel='Muy probable'
          // onChange={v => console.log(v)}
          defaultValue={probabilidadRecomendacion}
          />
        <div className='back-recommender-bottom'>
        </div>
        <div>
          <br></br>
          <h1 className='title'> Dejanos tu opinión</h1>
          </div>
          <Table className="table-bordered Tabla-Likes">
            <LikeOrDislike 
            name='atencion_tiempo_linea_de_caja'
            label='Atención y tiempo en línea de caja'
            />   
            <LikeOrDislike 
                name='promociones_ofertas_de_productos'
                label='Promociones y ofertas de productos'
            />   
            <LikeOrDislike 
                name='promociones_bancarias_medios_de_pago'
                label='Promociones bancarias y medios de pago'
            />
            <LikeOrDislike 
                name='visibilidad_precios_tienda'
                label='Visibilidad de los precios en la tienda'
            />
            <LikeOrDislike 
                name='precio_productos'
                label='Precios de los productos'
            />
            <LikeOrDislike 
                name='disponibilidad'
                label='Disponibilidad de productos y marcas'
            />
            <LikeOrDislike 
                name='marcas_propias'
                label='Marcas propias'
            />
            <LikeOrDislike 
                name='calidad_servicio_carniceria'
                label='Calidad y servicio en la carnicería'
            />            
            <LikeOrDislike 
                name='precio_carnes'
                label='Precio de las carnes'
            />
          </Table>
        <div>
          <br></br>
          <h4 className='title'> ¿Cómo podemos mejorar?</h4>
          </div>
        <FreeComment
          name='comentario_libre'
          label='¿Nos dejas algún comentario para mejorar?'
          required={false}
        />
        <div className='d-flex justify-content-center' name='btn_submit'>
          {!isSubmitting && !submitSuccess && (
            <Button type="submit" style={{ color: 'white', backgroundColor: 'rgba(215, 25, 33,255)' }} >Confirmar encuesta</Button>
          )}
          {isSubmitting && !submitSuccess && !submitError && <p>Enviando encuesta...</p>}
          {submitSuccess && <p>Encuesta enviada con éxito! Muchas gracias por tus respuestas.</p>}
        </div>
        <div className='d-flex justify-content-center'>
        {submitError && isSubmitting && !submitSuccess && (
            <Button type="submit" >Reintentar enviar encuesta</Button>
          )}
        </div>
        <div className='d-flex justify-content-center'>
        {isSubmitting && !submitSuccess && submitError && <p>Error en el envío de la encuesta, por favor intenta nuevamente.</p>}
        </div>
        <input type="hidden" name="tienda_id" defaultValue={tiendaId}/>
        <input type="hidden" name="envio_id" defaultValue={envioId}/>
        <input type="hidden" name="envio_fecha_hora" defaultValue={envioFechaHora}/>
        <input type="hidden" name="tipo_cliente" defaultValue={tipoCliente}/>
        <input type="hidden" name="cliente_nro" defaultValue={clienteNro}/>
        <input type="hidden" name="visita_fecha" defaultValue={visitaFecha}/>
        <input type="hidden" name="email_cliente" defaultValue={emailCliente}/>
        
      </form>
    </div>
  );
}

export default App;
